import React from 'react';
import { Outlet, Link } from "react-router-dom";
import axios from 'axios';
import { useModal } from './context/modal-context'


function Layout(){
  const { setModal } = useModal()


  return (
    <>
    <header className="header">
      <div className="container">
        <div className="header-top">


            <Link to="/" className="logo"></Link>


        <nav className="main-nav" aria-label="Main Menu">
          <ul>
            <li>
              <button
                onClick={() => {
                    axios.get('../json/privacy-policy.json').then(res => {
                    const modalData = {
                      type: "default",
                      content: res.data[0]
                    };
                    setModal(modalData)
                    });
                }}>Your Privacy
                <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.9862 4.64274C23.9862 4.1126 23.5502 3.66153 22.9754 3.60858C21.2731 3.41181 19.5975 3.0374 17.9778 2.49184C16.1263 1.82805 14.3204 1.04996 12.5711 0.162341C12.3989 0.0581564 12.2002 0.00292969 11.9974 0.00292969C11.7946 0.00292969 11.5959 0.0581564 11.4237 0.162341C9.70187 1.07079 7.90297 1.83408 6.04682 2.44384C4.40831 2.97468 2.7153 3.37281 1.04918 3.55776C0.779343 3.58611 0.528115 3.70495 0.338735 3.89383C0.149354 4.08271 0.0336637 4.32981 0.0115772 4.59262C-0.152637 10.9867 1.43138 16.293 4.68151 20.405C6.45663 22.6335 8.72293 24.4646 11.3998 25.8404C11.5633 25.9732 11.8089 25.9993 12 25.9993C12.191 25.9993 12.3829 25.9463 12.6285 25.8666C15.2501 24.4872 17.5164 22.6568 19.3191 20.4311C22.5685 16.3453 24.1525 11.039 23.9883 4.64486L23.9862 4.64274ZM21.637 5.59783C21.6101 11.1427 20.1896 15.7057 17.4045 19.2077C15.9116 21.0368 14.077 22.5759 11.997 23.7446C9.81212 22.498 8.01012 20.9591 6.62012 19.2077C3.83501 15.6782 2.41448 11.1159 2.3876 5.59783C3.92564 5.33863 5.44114 4.96601 6.92093 4.4832C8.66031 3.88532 10.357 3.17622 12 2.36054C13.8296 3.23586 15.5226 3.95236 17.0797 4.4832C18.5713 4.95374 20.0939 5.32612 21.637 5.59783Z" fill="white"/>
                <path d="M12.0119 16.7511C11.588 16.7511 11.1814 16.5874 10.8816 16.2962C10.5818 16.005 10.4134 15.6099 10.4134 15.1981V13.8568C9.78237 13.5133 9.28643 12.9761 9.00322 12.3295C8.72 11.6828 8.66551 10.9632 8.84826 10.2832C9.03101 9.60328 9.44069 9.00139 10.0132 8.5718C10.5856 8.14222 11.2886 7.90918 12.0119 7.90918C12.7353 7.90918 13.4383 8.14222 14.0107 8.5718C14.5832 9.00139 14.9929 9.60328 15.1756 10.2832C15.3584 10.9632 15.3039 11.6828 15.0207 12.3295C14.7375 12.9761 14.2415 13.5133 13.6105 13.8568V15.1981C13.6105 15.6099 13.4421 16.005 13.1423 16.2962C12.8425 16.5874 12.4359 16.7511 12.0119 16.7511ZM12.0119 9.03691C11.5219 9.03459 11.0464 9.19824 10.6672 9.49971C10.288 9.80118 10.0288 10.2216 9.93416 10.6887C9.83953 11.1558 9.91542 11.6403 10.1488 12.0589C10.3821 12.4775 10.7583 12.804 11.2127 12.9822L11.576 13.1234V15.1938C11.576 15.3062 11.6219 15.4139 11.7037 15.4933C11.7854 15.5728 11.8963 15.6174 12.0119 15.6174C12.1276 15.6174 12.2385 15.5728 12.3202 15.4933C12.402 15.4139 12.4479 15.3062 12.4479 15.1938V13.1227L12.8112 12.9815C13.2656 12.8033 13.6418 12.4768 13.8751 12.0582C14.1085 11.6396 14.1844 11.1551 14.0897 10.688C13.9951 10.2209 13.7359 9.80047 13.3567 9.499C12.9774 9.19753 12.5019 9.03388 12.0119 9.0362V9.03691Z" fill="white"/>

                </svg>
                </button>
            </li>
            <li>
              <button
              onClick={() => {
                  axios.get('../json/how-to-use-tool.json').then(res => {
                  const modalData = {
                    type: "default",
                    content: res.data[0]
                  };
                    setModal(modalData)
                  });
              }}
              >How to use this tool
              <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.15999 23L0 0L21 9.93955L9.53074 12.4795L4.15999 23ZM3.43468 4.4966L5.3366 15.0105L7.79307 10.2009L13.037 9.04072L3.43468 4.4966Z" fill="#ffffff"/>
              </svg>

              </button>
            </li>
            <li>
              <Link to="/assessments">Assessment Dashboard
              <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M64.6562 16.167H72.7396C74.8834 16.167 76.9394 17.0186 78.4554 18.5345C79.9713 20.0505 80.8229 22.1065 80.8229 24.2503V80.8337C80.8229 82.9775 79.9713 85.0335 78.4554 86.5494C76.9394 88.0654 74.8834 88.917 72.7396 88.917H24.2396C22.0957 88.917 20.0397 88.0654 18.5238 86.5494C17.0079 85.0335 16.1563 82.9775 16.1562 80.8337V24.2503C16.1562 22.1065 17.0079 20.0505 18.5238 18.5345C20.0397 17.0186 22.0957 16.167 24.2396 16.167H32.3229" stroke="#ffffff" stroke-width="6.91667" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M60.6354 8.08203H36.3854C34.1533 8.08203 32.3438 9.89155 32.3438 12.1237V20.207C32.3438 22.4392 34.1533 24.2487 36.3854 24.2487H60.6354C62.8676 24.2487 64.6771 22.4392 64.6771 20.207V12.1237C64.6771 9.89155 62.8676 8.08203 60.6354 8.08203Z" stroke="#ffffff" stroke-width="6.91667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>


              </Link>
            </li>
          </ul>
        </nav>
        </div>
      </div>
    </header>

    <div className="outer-container">


    <Outlet />
    </div>
    </>
  )


};

export default Layout;
