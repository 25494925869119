import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Layout";
import Home from "./Home";
import Assessments from './Assessments';
import Assessment from './Assessment';

function App() {
  return (
    <div className="body">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="assessments" element={<Assessments />}  />
          <Route path="assessment" element={<Assessment />}  />

        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
