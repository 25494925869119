import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useModal } from './context/modal-context'
import axios from 'axios';

import Cta from "./components/Cta";


export default function CheckpointCTAs() {
  const activeCheckpoint = useSelector((state) => state.checkpoints.activeCheckpoint);
  const { setModal } = useModal()

  // if ( !activeCheckpoint || activeCheckpoint.length === 0) return <p></p>;

  return (
    <>
    <div className="checkpoint-ctas" >
    <div className="checkpoint-ctas-inner" >


      <div className="checkpoint-cta-title">More on this subject</div>
      <button className="checkpoint-cta background"
        onClick={() => {
          const modalContent = {
            type: "default",
            content: activeCheckpoint.background_info[0]
          };
          setModal(modalContent)
        }}><span>Background Info</span>
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4997 27C10.3764 26.9999 7.34972 25.9169 4.93543 23.9354C2.52115 21.954 0.868599 19.1967 0.259353 16.1334C-0.349893 13.0701 0.121863 9.89031 1.59424 7.13585C3.06662 4.38139 5.44851 2.22267 8.33409 1.02751C11.2197 -0.167659 14.4304 -0.325312 17.4192 0.581399C20.4079 1.48811 22.9899 3.40309 24.725 6.00005C26.4602 8.59701 27.2412 11.7153 26.935 14.8235C26.6288 17.9317 25.2543 20.8377 23.0458 23.0461C21.7951 24.3037 20.3074 25.3006 18.6688 25.9793C17.0302 26.658 15.2732 27.0049 13.4997 27ZM13.4997 2.45536C11.3152 2.45536 9.17986 3.10311 7.36358 4.31671C5.54729 5.53031 4.13167 7.25526 3.29573 9.2734C2.45979 11.2915 2.24106 13.5123 2.66722 15.6547C3.09338 17.7972 4.14528 19.7651 5.6899 21.3097C7.23452 22.8544 9.2025 23.9063 11.3449 24.3324C13.4874 24.7586 15.7081 24.5399 17.7263 23.7039C19.7444 22.868 21.4693 21.4524 22.6829 19.6361C23.8965 17.8198 24.5443 15.6844 24.5443 13.5C24.5414 10.5717 23.3768 7.76415 21.3062 5.69351C19.2355 3.62287 16.428 2.4583 13.4997 2.45536Z" fill="white"/>
        <path d="M13.5 20.2223C13.3389 20.2223 13.1794 20.1905 13.0305 20.1288C12.8817 20.0671 12.7465 19.9766 12.6327 19.8626C12.5189 19.7486 12.4286 19.6132 12.3672 19.4643C12.3057 19.3154 12.2743 19.1558 12.2745 18.9946V12.1925C12.2675 12.027 12.294 11.8618 12.3525 11.7069C12.4109 11.5519 12.5002 11.4104 12.6148 11.2908C12.7293 11.1713 12.8669 11.0761 13.0193 11.0111C13.1716 10.9461 13.3355 10.9126 13.5011 10.9126C13.6667 10.9126 13.8306 10.9461 13.983 11.0111C14.1353 11.0761 14.2729 11.1713 14.3875 11.2908C14.5021 11.4104 14.5913 11.5519 14.6497 11.7069C14.7082 11.8618 14.7347 12.027 14.7277 12.1925V18.9946C14.728 19.156 14.6964 19.3157 14.6348 19.4648C14.5732 19.6139 14.4828 19.7493 14.3688 19.8634C14.2547 19.9775 14.1192 20.0679 13.9702 20.1295C13.8211 20.1911 13.6613 20.2226 13.5 20.2223Z" fill="white"/>
        <path d="M13.5033 8.81815C13.2604 8.81858 13.0228 8.74695 12.8207 8.61232C12.6185 8.47769 12.4608 8.28612 12.3675 8.06183C12.2743 7.83754 12.2496 7.59061 12.2968 7.35232C12.3439 7.11403 12.4606 6.89507 12.6322 6.72316C12.8038 6.55124 13.0226 6.4341 13.2608 6.38655C13.499 6.339 13.746 6.36318 13.9704 6.45603C14.1949 6.54889 14.3867 6.70624 14.5217 6.90818C14.6567 7.11012 14.7288 7.34757 14.7288 7.59048C14.7288 7.91569 14.5998 8.22761 14.37 8.45778C14.1402 8.68795 13.8286 8.81756 13.5033 8.81815Z" fill="white"/>
        </svg>
      </button>

      <button className="checkpoint-cta example"
        onClick={() => {
          const modalContent = {
            type: "default",
            content: activeCheckpoint.examples[0]
          };
          setModal(modalContent)
        }}><span>Examples</span>
        <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3313 24.0195H2.35873C1.73329 24.0191 1.13359 23.7704 0.691337 23.3282C0.249085 22.8859 0.000436911 22.2862 0 21.6608V2.36215C0.000872722 1.73684 0.24966 1.1374 0.691818 0.695241C1.13398 0.253083 1.73342 0.00429069 2.35873 0.00341797H16.3313C16.9566 0.00429069 17.556 0.253083 17.9982 0.695241C18.4403 1.1374 18.6891 1.73684 18.69 2.36215V21.6608C18.6891 22.2861 18.4403 22.8855 17.9982 23.3277C17.556 23.7699 16.9566 24.0186 16.3313 24.0195ZM2.35873 1.68092C2.17718 1.68092 2.00307 1.75304 1.87469 1.88141C1.74632 2.00979 1.6742 2.1839 1.6742 2.36544V21.6641C1.6742 21.8456 1.74632 22.0198 1.87469 22.1481C2.00307 22.2765 2.17718 22.3486 2.35873 22.3486H16.3313C16.5128 22.3486 16.6869 22.2765 16.8153 22.1481C16.9437 22.0198 17.0158 21.8456 17.0158 21.6641V2.36544C17.0158 2.1839 16.9437 2.00979 16.8153 1.88141C16.6869 1.75304 16.5128 1.68092 16.3313 1.68092H2.35873Z" fill="white"/>
        <path d="M13.7955 4.29672H5.07976C4.95836 4.29152 4.83716 4.31095 4.72346 4.35382C4.60976 4.3967 4.50592 4.46214 4.41818 4.5462C4.33044 4.63027 4.26061 4.73122 4.21291 4.84298C4.16522 4.95473 4.14063 5.07499 4.14062 5.19651C4.14063 5.31802 4.16522 5.43828 4.21291 5.55003C4.26061 5.66179 4.33044 5.76274 4.41818 5.84681C4.50592 5.93087 4.60976 5.99631 4.72346 6.03918C4.83716 6.08206 4.95836 6.10148 5.07976 6.09628H13.7955C13.9169 6.10148 14.0381 6.08206 14.1518 6.03918C14.2655 5.99631 14.3693 5.93087 14.4571 5.84681C14.5448 5.76274 14.6146 5.66179 14.6623 5.55003C14.71 5.43828 14.7346 5.31802 14.7346 5.19651C14.7346 5.07499 14.71 4.95473 14.6623 4.84298C14.6146 4.73122 14.5448 4.63027 14.4571 4.5462C14.3693 4.46214 14.2655 4.3967 14.1518 4.35382C14.0381 4.31095 13.9169 4.29152 13.7955 4.29672Z" fill="white"/>
        <path d="M13.796 8.83789H5.08029C4.84143 8.83789 4.61236 8.93277 4.44347 9.10167C4.27457 9.27056 4.17969 9.49964 4.17969 9.73849C4.17969 9.97735 4.27457 10.2064 4.44347 10.3753C4.61236 10.5442 4.84143 10.6391 5.08029 10.6391H13.796C14.0349 10.6391 14.264 10.5442 14.4328 10.3753C14.6017 10.2064 14.6966 9.97735 14.6966 9.73849C14.6966 9.49964 14.6017 9.27056 14.4328 9.10167C14.264 8.93277 14.0349 8.83789 13.796 8.83789Z" fill="white"/>
        <path d="M13.796 13.3804H5.08029C4.84143 13.3804 4.61236 13.4753 4.44347 13.6442C4.27457 13.8131 4.17969 14.0421 4.17969 14.281C4.17969 14.5198 4.27457 14.7489 4.44347 14.9178C4.61236 15.0867 4.84143 15.1816 5.08029 15.1816H13.796C14.0349 15.1816 14.264 15.0867 14.4328 14.9178C14.6017 14.7489 14.6966 14.5198 14.6966 14.281C14.6966 14.0421 14.6017 13.8131 14.4328 13.6442C14.264 13.4753 14.0349 13.3804 13.796 13.3804Z" fill="white"/>
        <path d="M13.796 17.9229H5.08029C4.84143 17.9229 4.61236 18.0177 4.44347 18.1866C4.27457 18.3555 4.17969 18.5846 4.17969 18.8235C4.17969 19.0623 4.27457 19.2914 4.44347 19.4603C4.61236 19.6292 4.84143 19.7241 5.08029 19.7241H13.796C14.0349 19.7241 14.264 19.6292 14.4328 19.4603C14.6017 19.2914 14.6966 19.0623 14.6966 18.8235C14.6966 18.5846 14.6017 18.3555 14.4328 18.1866C14.264 18.0177 14.0349 17.9229 13.796 17.9229Z" fill="white"/>

        </svg>
        </button>


      <button className="checkpoint-cta mitigating-actions"
        onClick={() => {
          const modalContent = {
            type: "default",
            content: activeCheckpoint.mitigating_actions[0]
          };
          setModal(modalContent)
        }}><span>Mitigating actions you can take</span>
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.537 9.67057L20.4597 7.59325C20.2643 7.40119 20.0009 7.29415 19.7269 7.29542C19.4529 7.2967 19.1904 7.40619 18.9968 7.60005C18.8032 7.79392 18.694 8.05646 18.693 8.33047C18.6921 8.60448 18.7994 8.86776 18.9917 9.06297L19.2964 9.36591H17.6501C16.8426 9.36657 16.0463 9.556 15.325 9.91908C14.6036 10.2822 13.9771 10.8088 13.4955 11.4571C13.014 10.8086 12.3876 10.2818 11.6662 9.91871C10.9448 9.55561 10.1485 9.3663 9.34083 9.36591H7.69282L7.9975 9.06297C8.18979 8.86776 8.29715 8.60448 8.29619 8.33047C8.29524 8.05646 8.18606 7.79392 7.99242 7.60005C7.79878 7.40619 7.53638 7.2967 7.26237 7.29542C6.98837 7.29415 6.72496 7.40119 6.52952 7.59325L4.4522 9.67057C4.25769 9.86532 4.14844 10.1293 4.14844 10.4046C4.14844 10.6798 4.25769 10.9438 4.4522 11.1386L6.52952 13.2159C6.72642 13.3993 6.98684 13.4992 7.25592 13.4945C7.52501 13.4897 7.78174 13.3807 7.97205 13.1904C8.16235 13.0001 8.27135 12.7434 8.2761 12.4743C8.28085 12.2052 8.18097 11.9448 7.9975 11.7479L7.69282 11.4432H9.34083C10.1672 11.4432 10.9598 11.7715 11.5442 12.3559C12.1285 12.9402 12.4568 13.7328 12.4568 14.5592V21.8298C12.4568 22.1053 12.5662 22.3695 12.761 22.5643C12.9558 22.7591 13.22 22.8685 13.4955 22.8685C13.7709 22.8685 14.0351 22.7591 14.2299 22.5643C14.4247 22.3695 14.5341 22.1053 14.5341 21.8298V14.5592C14.5341 13.7328 14.8624 12.9402 15.4468 12.3559C16.0311 11.7715 16.8237 11.4432 17.6501 11.4432H19.2964L18.9917 11.7479C18.8897 11.843 18.8078 11.9577 18.7511 12.0851C18.6943 12.2125 18.6638 12.35 18.6613 12.4895C18.6588 12.6289 18.6845 12.7675 18.7367 12.8968C18.789 13.0261 18.8667 13.1436 18.9654 13.2422C19.064 13.3409 19.1815 13.4186 19.3108 13.4709C19.4401 13.5231 19.5787 13.5488 19.7181 13.5463C19.8576 13.5438 19.9951 13.5133 20.1225 13.4565C20.2499 13.3998 20.3646 13.3179 20.4597 13.2159L22.537 11.1386C22.7315 10.9438 22.8408 10.6798 22.8408 10.4046C22.8408 10.1293 22.7315 9.86532 22.537 9.67057Z" fill="white"/>
        <path d="M13.5026 0.0195312C10.832 0.0195313 8.22145 0.811447 6.00096 2.29513C3.78047 3.77882 2.04981 5.88763 1.02783 8.35491C0.00585189 10.8222 -0.261546 13.5371 0.259454 16.1564C0.780455 18.7756 2.06645 21.1815 3.95482 23.0699C5.8432 24.9583 8.24913 26.2443 10.8684 26.7653C13.4876 27.2863 16.2026 27.0189 18.6698 25.9969C21.1371 24.9749 23.2459 23.2443 24.7296 21.0238C26.2133 18.8033 27.0052 16.1927 27.0052 13.5221C27.002 9.942 25.5784 6.50943 23.0468 3.9779C20.5153 1.44636 17.0827 0.0227399 13.5026 0.0195312ZM13.5026 24.9474C11.2429 24.9474 9.03394 24.2773 7.15506 23.0219C5.27618 21.7665 3.81178 19.9821 2.94703 17.8944C2.08227 15.8067 1.85602 13.5095 2.29686 11.2932C2.73771 9.07689 3.82586 7.0411 5.42372 5.44325C7.02157 3.84539 9.05736 2.75724 11.2736 2.31639C13.4899 1.87554 15.7872 2.10181 17.8749 2.96656C19.9626 3.83131 21.7469 5.29571 23.0024 7.17459C24.2578 9.05347 24.9279 11.2624 24.9279 13.5221C24.9238 16.5506 23.7185 19.4537 21.5766 21.5946C19.4347 23.7356 16.531 24.9395 13.5026 24.9422V24.9474Z" fill="white"/>

        </svg>
      </button>



      </div>
      <button className="view-report-cta" onClick={() => {
          axios.get('./json/report.json').then(res => {
            const modalData = {
              type: "report",
              content: res.data[0]
            };
            setModal(modalData);
          });
        }}
      >
      <div className="cta-inner">
        <div className="cta-title">View your report</div>
        <div className="cta-content">

          <div className="cta-text">You can review your report at anytime. However, the more checkpoints you tackle, the more useful the report will be.</div>

        </div>

        <div class="button button-white">
          Read the report
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="10.1424" y1="0.431885" x2="10.1425" y2="19.5679" stroke="#2254F4" stroke-width="2.2849"/>
          <line x1="0.429688" y1="9.85706" x2="19.5657" y2="9.85706" stroke="#2254F4" stroke-width="2.2849"/>
          </svg>

        </div>
        </div>
      </button>
    </div>


    </>
  );
};
